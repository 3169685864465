import React from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const FileUploader = () => {
  const onFileUpload = (info) => {
    // Process the uploaded file (.doc or .docx) here
  };

  const fileProps = {
    name: 'file',
    multiple: false,
    accept: '.doc,.docx',
    showUploadList: false,
    beforeUpload: (file) => {
      const isDoc = file.type === 'application/msword';
      const isDocx = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

      if (!isDoc && !isDocx) {
        message.error('You can only upload .doc or .docx files!');
        return false;
      }

      return true;
    },
    onChange: onFileUpload,
  };

  return (
    <Dragger {...fileProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">Support for a single .doc or .docx file upload.</p>
    </Dragger>
  );
};

export default FileUploader;
import React, { useState } from 'react';
import { Tabs, Modal, Input } from 'antd';
import FileUploader from './FileUploader';

const { TabPane } = Tabs;

const ModalContent = ({ visible, handleOk, handleCancel }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [dataName, setDataName] = useState('');

  const handleOkClick = () => {
    if (dataName.trim()) {
      handleOk(dataName);
      setDataName('');
    } else {
      alert('need data')
    }
  };

  return (
    <Modal
      title="Add Data"
      visible={visible}
      onOk={handleOkClick}
      onCancel={handleCancel}
      width={600}
    >
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        <TabPane tab="Write" key="1">
          <Input
            placeholder="Name of data"
            value={dataName}
            onChange={(e) => setDataName(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
          <Input.TextArea
            placeholder="Write your own data here..."
            autoSize={{ minRows: 6 }}
          />
        </TabPane>
        <TabPane tab="Upload" key="2">
          <Input
            placeholder="Name of data"
            value={dataName}
            onChange={(e) => setDataName(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
          <FileUploader />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ModalContent;

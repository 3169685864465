import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'
import { MessageOutlined } from '@ant-design/icons'
import Sidebar from './components/Sidebar'
import ModalContent from './components/ModalContent'
import Chat from './components/Chat'
import 'antd/dist/reset.css'
import './index.css'
import './App.css'
import menuItems from './configs/menuItems'
import createPersistedState from 'use-persisted-state'
// import 'antd/dist/antd.css';
import axios from 'axios'

const { Header } = Layout

const App = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [dataNames, setDataNames] = useState([])
  const useSelectedKeysState = createPersistedState('selectedKeys')
  const [selectedKeys, setSelectKeys] = useSelectedKeysState([])
  const [currentCategory, setCurrentCategory] = useState('')
  const [currentType, setCurrentType] = useState('text')

  const handleAddButtonClick = () => {
    setModalVisible(true)
  }

  const handleMenuClick = (e) => {
    const [category, type] = e.key.split('::')
    setCurrentCategory(category)
    setCurrentType(type)
    setSelectKeys([e.key])
  }

  const handleModalOk = (dataName) => {
    setDataNames([...dataNames, dataName])
    setModalVisible(false)
  }

  const handleModalCancel = () => {
    setModalVisible(false)
  }

  console.log('selectedKeys', selectedKeys)

  const [categories, setCategories] = useState([])
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        'https://staging-api.acaya.ai/chatbot/v2/categories'
      )
      const categories = response.data.categories
      setCategories(categories)
      setSelectKeys([`${categories[0].title}::${categories[0].type}`])
      setCurrentCategory(categories[0].title)
      setCurrentType(categories[0].type)
    } catch (error) {
      console.log(error)
    }
  }

  const prepareMenuItems = (categories) => {
    const items = categories.map((category) => {
      const label = menuItems.find((item) => item.key === category.title)?.label
      let type = category.type === 'csv' ? 'Table' : 'File'
      return {
        key: `${category.title}::${category.type}`,
        label: `${label || category.title} (${
          category.sourceCount > 1 ? 'Multiple ' : ''
        }${type}${category.sourceCount > 1 ? 's' : ''})`,
      }
    })
    return items
  }

  useEffect(() => {
    // console.log('Sidebar rendered')
    // fetchCategories()
  }, [])

  return (
    <Layout>
      <Header
        style={{
          color: 'white',
          fontSize: '24px',
          textAlign: 'center',
          backgroundColor: '#2D3BEA',
          position: 'fixed',
          left: '0',
          top: '0',
          width: '100%',
          zIndex: '10',
        }}
      >
        <MessageOutlined /> Acaya Chatbot
      </Header>
      <Layout>
        {/* <Sidebar
          onAddButtonClick={handleAddButtonClick}
          onMenuClick={handleMenuClick}
          selectedKeys={selectedKeys}
          menuItems={menuItems}
        /> */}
        <Chat
          selectedKeys={selectedKeys}
          currentCategory={currentCategory}
          currentType={currentType}
          categories={categories}
        />
      </Layout>
      <ModalContent
        visible={modalVisible}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
      />
    </Layout>
  )
}

export default App

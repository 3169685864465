import React from 'react'
import { Button, Layout, List, Menu } from 'antd'
import { PlusOutlined, FileTextOutlined } from '@ant-design/icons'

const { Sider } = Layout

const Sidebar = ({
  onAddButtonClick,
  dataNames,
  menuItems,
  onMenuClick,
  selectedKeys,
}) => {
  return (
    <Sider
      style={{
        position: 'fixed',
        left: '0',
        top: '0',
        zIndex: '9 ',
        paddingTop: '64px',
        minHeight: '100vh',
      }}
      className="sidebar"
      breakpoint="sm"
      collapsedWidth="0"
      theme="light"
      width={200}
    >
      {/* <Button
        type="default"
        icon={<PlusOutlined />}
        onClick={onAddButtonClick}
        style={{ marginBottom: '16px', width: '100%' }}
      >
        Add New Data
      </Button> */}
      {/* <List
        className="data-list"
        dataSource={dataNames}
        renderItem={(item) => (
          <List.Item>
           <FileTextOutlined /> {item}
          </List.Item>
        )}
      /> */}
      <Menu
        items={menuItems}
        onClick={onMenuClick}
        selectedKeys={selectedKeys}
      />
    </Sider>
  )
}

export default Sidebar

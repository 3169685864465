import React, { useEffect, useState, useCallback } from 'react'
import {
  Layout,
  Input,
  Button,
  Row,
  Col,
  Popconfirm,
  List,
  Drawer,
  Card,
  Table,
} from 'antd'
import {
  LoadingOutlined,
  SendOutlined,
  BorderOutlined,
} from '@ant-design/icons'
import axios from 'axios'
import createPersistedState from 'use-persisted-state'
import { v4 as uuidv4 } from 'uuid'

const { Content } = Layout

const Chat = ({ currentCategory, currentType, selectedKeys, categories }) => {
  const [isChatRequesting, setIsChatRequesting] = useState(false) // Track if a request is in progress

  const useMessagesState = createPersistedState('bluescopeMessages')
  const useUserIdState = createPersistedState('userId')
  const useDisableChatInputState = createPersistedState('disableChatInput')
  const [messages, setMessages] = useMessagesState([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([])
  const [open, setOpen] = useState(false)
  const [documentItem, setDocumentItem] = useState({})
  const [exampleQuestions, setExampleQuestions] = useState([])
  const [isExpandQuestion, setIsExpandQuestion] = useState(false)
  const [axiosSource, setAxiosSource] = useState(null)
  const [controller, setController] = useState(null)

  const [userId, setUserId] = useUserIdState('')

  const [disableChatInput, setDisableChatInput] = useDisableChatInputState(true)

  const isTimeString = (timeString) => {
    const regex = /^(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2}):(\d{2}).(\d{6})$/
    return regex.test(timeString)
  }

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      // set user message
      const updatedChats = [...messages, { text: inputValue, sender: 'user' }]
      setDisableChatInput(true)
      setLoading(true)
      // set bot generating message
      setMessages([
        ...updatedChats,
        { text: 'Generating answers, please wait…', sender: 'bot' },
      ])

      // fetch chat reponse
      chat(userId, inputValue, updatedChats)

      // clear input
      setInputValue('')
    }
  }

  const chat = async (userId, text, updatedChats) => {
    try {
      setIsChatRequesting(true)
      const response = await axios.post(
        'https://fdce-45-32-105-159.ngrok-free.app/chatbot',
        {
          user_id: userId,
          text,
        }
        // { cancelToken: axiosSource.token }
      )
      setLoading(false)
      setIsChatRequesting(false)
      let botText = response.data.text
      botText = botText.replace('🤖 : ', '')

      // split text to get answer
      const splitedText = botText.split(
        '🤖 : ได้คำตอบที่ต้องการหรือไม่ (ใช่/ไม่)'
      )

      // If match found then return the result
      if (splitedText.length > 1) {
        // console.log(splitedText)
        const extractedText = splitedText[0].trim()
        const answerText = extractedText
        botText = 'ได้คำตอบที่ต้องการหรือไม่ (ใช่/ไม่)'
        setMessages([
          ...updatedChats,
          { text: answerText, sender: 'bot' },
          { text: botText, sender: 'bot' },
        ])
      } else if (updatedChats?.length > 0) {
        setMessages([...updatedChats, { text: botText, sender: 'bot' }])
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: botText, sender: 'bot' },
        ])
      }
      if (
        botText !==
          'น้องหลังคายินดีช่วยเหลือ ต้องการสอบถามเรื่องอะไร\n1. ช่างติดตั้งมาตรฐานบลูสโคป_และการอบรมช่าง\n2. ตัวแทนจำหน่าย_และช่องทางซื้อสินค้า\n3. ผลิตภัณฑ์และแบรนด์สินค้าของบลูสโคป\n4. ปัญหาการติดตั้ง การใช้งาน และการดูแลรักษา\n5. การรับประกันสินค้า' &&
        botText !== 'ได้คำตอบที่ต้องการหรือไม่ (ใช่/ไม่)' &&
        botText !== 'สอบถามเพิ่มเติมไหม (ใช่/ไม่)' &&
        botText !== 'ไม่มีคำตอบสำหรับคำถามนี้'
      ) {
        setDisableChatInput(false)
      }
      if (botText === 'ไม่มีคำตอบสำหรับคำถามนี้') {
        setUserId('')
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const cancelChatRequest = () => {
  //   axiosSource.cancel('Request canceled by user')
  //   setIsChatRequesting(false)
  // }

  // clear chat
  const clearChat = () => {
    // if (isChatRequesting) {
    //   cancelChatRequest()
    // }
    setMessages([])
    setUserId('')
  }

  useEffect(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
    })
  }, [documents, exampleQuestions])

  useEffect(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    })
  }, [messages])

  useEffect(() => {
    if (!userId) {
      const userId = uuidv4()
      // setAxiosSource(axios.CancelToken.source())
      setUserId(userId)
      console.log('new userId: ', userId)
      chat(userId, 'เริ่ม')
      setDisableChatInput(true)
    } else {
      // setAxiosSource(axios.CancelToken.source())
      console.log('current userId: ', userId)
    }
  }, [userId])

  const onClickQuestion = (question) => {
    console.log('question: ', question)

    // น้องหลังคายินดีช่วยเหลือ ต้องการสอบถามเรื่องอะไร\n1. ช่างติดตั้งมาตรฐานบลูสโคป_และการอบรมช่าง\n2. ตัวแทนจำหน่าย_และช่องทางซื้อสินค้า\n3. ผลิตภัณฑ์และแบรนด์สินค้าของบลูสโคป\n4. ปัญหาการติดตั้ง การใช้งาน และการดูแลรักษา\n5. การรับประกันสินค้า

    let preparedQuestion = question
    if (question === 'ช่างติดตั้งมาตรฐานบลูสโคป และการอบรมช่าง') {
      preparedQuestion = 'ช่างติดตั้งมาตรฐานบลูสโคป_และการอบรมช่าง'
    }
    if (question === 'ตัวแทนจำหน่าย และช่องทางซื้อสินค้า') {
      preparedQuestion = 'ตัวแทนจำหน่าย_และช่องทางซื้อสินค้า'
    }
    // if (question === 'ปัญหาการติดตั้ง การใช้งาน และการดูแลรักษา') {
    //   preparedQuestion = 'ปัญหาการติดตั้ง_การใช้งาน_และการดูแลรักษา'
    // }
    // set user message
    let newMessages = [...messages]
    // edit last message
    newMessages[newMessages.length - 1].text =
      'น้องหลังคายินดีช่วยเหลือ ต้องการสอบถามเรื่องอะไร?'

    const updatedChats = [...newMessages, { text: question, sender: 'user' }]
    setDisableChatInput(true)
    setLoading(true)
    // set bot generating message
    setMessages([
      ...updatedChats,
      { text: 'Generating answers, please wait…', sender: 'bot' },
    ])

    // fetch chat reponse
    chat(userId, preparedQuestion, updatedChats)

    // clear input
    setInputValue('')
  }

  const onClickAnswer = (answer) => {
    // set user message
    let newMessages = [...messages]
    // edit last message
    newMessages[newMessages.length - 1].text = 'ได้คำตอบที่ต้องการหรือไม่?'

    const updatedChats = [...newMessages, { text: answer, sender: 'user' }]
    setDisableChatInput(true)

    if (answer === 'ใช่, สิ้นสุดการสนทนา') {
      // set bot generating message
      setMessages([
        ...updatedChats,
        {
          text: 'น้องหลังคาเต็มใจให้บริการ, หากต้องการสอบถามเพิ่มเติม กรุณากดปุ่ม "เริ่มใหม่"',
          sender: 'bot',
        },
      ])
      return null
    }

    // set bot generating message
    setMessages([
      ...updatedChats,
      { text: 'Generating answers, please wait…', sender: 'bot' },
    ])

    // fetch chat reponse
    chat(userId, 'ไม่', updatedChats)

    // clear input
    setInputValue('')
  }

  const onClickAskMore = (answer) => {
    // set user message
    let newMessages = [...messages]
    // edit last message
    newMessages[newMessages.length - 1].text = 'ต้องการสอบถามเพิ่มเติมหรือไม่?'

    const updatedChats = [...newMessages, { text: answer, sender: 'user' }]
    setDisableChatInput(true)

    // set bot generating message
    setMessages([
      ...updatedChats,
      { text: 'Generating answers, please wait…', sender: 'bot' },
    ])

    // fetch chat reponse
    chat(userId, answer, updatedChats)

    // clear input
    setInputValue('')
  }

  const ListQuestions = ({
    header,
    dataSource,
    rowStyle,
    colGrid,
    onClickButton,
  }) => {
    return (
      <Row
        type="flex"
        gutter={[16, 16]}
        style={{
          marginBottom: '20px',
          ...rowStyle,
        }}
      >
        <Col {...colGrid} style={{ minWidth: '450px' }}>
          <List
            header={<strong>{header}</strong>}
            dataSource={dataSource}
            bordered
            renderItem={(item) => (
              <List.Item
                key={item}
                // onClick={() => {
                //   onClickQuestion(item)
                // }}
                actions={[
                  <Button
                    onClick={() => {
                      onClickButton(item)
                    }}
                    key={`a-${item}`}
                    disabled={loading}
                    type="link"
                  >
                    เลือก
                  </Button>,
                ]}
              >
                <List.Item.Meta key={`b-${item}`} />
                {item}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    )
  }

  // console.log('messages: ', messages)

  return (
    <Content
      className="chat-container"
      style={
        loading
          ? {
              paddingBottom: '125px',
            }
          : null
      }
    >
      <div className="chat">
        {messages.map((message, index) => {
          console.log('message.text: ', message.text)
          if (
            // message.text ===
            // 'น้องหลังคายินดีช่วยเหลือ ต้องการสอบถามเรื่องอะไร\n1. ผลิตภัณฑ์และแบรนด์สินค้าของบลูสโคป\n2. ช่างติดตั้งมาตรฐานบลูสโคป และการอบรมช่าง\n3. ตัวแทนจำหน่าย และช่องทางซื้อสินค้า\n4. ปัญหาการติดตั้ง การใช้งาน และการดูแลรักษา\n5. การรับประกันสินค้า'
            message.text ===
            'น้องหลังคายินดีช่วยเหลือ ต้องการสอบถามเรื่องอะไร\n1. ช่างติดตั้งมาตรฐานบลูสโคป_และการอบรมช่าง\n2. ตัวแทนจำหน่าย_และช่องทางซื้อสินค้า\n3. ผลิตภัณฑ์และแบรนด์สินค้าของบลูสโคป\n4. ปัญหาการติดตั้ง การใช้งาน และการดูแลรักษา\n5. การรับประกันสินค้า'
          ) {
            return (
              <ListQuestions
                rowStyle={
                  index > 0
                    ? { float: 'left', clear: 'left', marginTop: '70px' }
                    : null
                }
                key={index}
                header="น้องหลังคายินดีช่วยเหลือ ต้องการสอบถามเรื่องอะไร?"
                dataSource={[
                  'ผลิตภัณฑ์และแบรนด์สินค้าของบลูสโคป',
                  'ช่างติดตั้งมาตรฐานบลูสโคป และการอบรมช่าง',
                  'ตัวแทนจำหน่าย และช่องทางซื้อสินค้า',
                  'ปัญหาการติดตั้ง การใช้งาน และการดูแลรักษา',
                  'การรับประกันสินค้า',
                ]}
                onClickButton={onClickQuestion}
              />
            )
          } else if (message.text === 'ได้คำตอบที่ต้องการหรือไม่ (ใช่/ไม่)') {
            return (
              <ListQuestions
                rowStyle={{ float: 'left', clear: 'left', marginTop: '10px' }}
                key={index}
                header="ได้คำตอบที่ต้องการหรือไม่?"
                dataSource={['ใช่, สิ้นสุดการสนทนา', 'ไม่, ถามเพิ่ม']}
                onClickButton={onClickAnswer}
              />
            )
          } else if (message.text === 'สอบถามเพิ่มเติมไหม (ใช่/ไม่)') {
            return (
              <ListQuestions
                rowStyle={{ float: 'left', clear: 'left', marginTop: '70px' }}
                key={index}
                header="ต้องการสอบถามเพิ่มเติมหรือไม่?"
                dataSource={['ใช่', 'ไม่']}
                onClickButton={onClickAskMore}
              />
            )
          }
          return (
            <div
              key={index}
              className={`chat-message ${
                message.sender === 'user' ? 'user-message' : 'bot-message'
              }`}
              dangerouslySetInnerHTML={{ __html: message.text }}
            ></div>
          )
        })}
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: '12px',
          left: '24px',
          width: 'calc(100% - 48px)',
        }}
      >
        <Card size="small">
          <Row gutter={16}>
            <Col flex>
              <Popconfirm
                title="คุณต้องการเริ่มการสอบถามใหม่?"
                onConfirm={clearChat}
                okText="Yes"
                cancelText="No"
              >
                <Button size="large">
                  {/* <DeleteOutlined /> */}
                  เริ่มใหม่
                </Button>
              </Popconfirm>
            </Col>
            <Col flex="auto">
              <Input.Search
                disabled={disableChatInput}
                value={inputValue}
                size="large"
                onChange={(e) => setInputValue(e.target.value)}
                onSearch={handleSendMessage}
                enterButton={loading ? <LoadingOutlined /> : <SendOutlined />}
                // placeholder="Ask me anything..."
              />
            </Col>
          </Row>
        </Card>
      </div>
    </Content>
  )
}

export default Chat
